// import 'isomorphic-base64';
// import user from '../user';

// /**
//  * Returns the Authorization header as part of an Object.
//  *
//  * @export
//  * @param {Object} [server={}]
//  * @param {Object} [server.requestOptions]
//  * @param {string|function} [server.requestOptions.auth]
//  * @returns {Object} { Authorization }
//  */
// export default function getAuthorizationHeader({ requestOptions } = {}, user) {
//   const headers = {};

//   // Check for OHIF.user since this can also be run on the server
//   const accessToken = user && user.getAccessToken && user.getAccessToken();

//   // Auth for a specific server
//   if (requestOptions && requestOptions.auth) {
//     if (typeof requestOptions.auth === 'function') {
//       // Custom Auth Header
//       headers.Authorization = requestOptions.auth(requestOptions);
//     } else {
//       // HTTP Basic Auth (user:password)
//       headers.Authorization = `Basic ${btoa(requestOptions.auth)}`;
//     }
//   }
//   // Auth for the user's default
//   else if (accessToken) {
//     headers.Authorization = `Bearer ${accessToken}`;
//   }

//   return headers;
// }


import 'isomorphic-base64'
import user from '../user';

/**
 * Returns the Authorization header as part of an Object.
 *
 * @export
 * @param {Object} [server={}]
 * @param {Object} [server.requestOptions]
 * @param {string|function} [server.requestOptions.auth]
 * @returns {Object} { Authorization }
 */
export default function getAuthorizationHeader({ requestOptions } = {}, user) {
  const headers = {};

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  // Check for OHIF.user since this can also be run on the server
  const accessToken = user && user.getAccessToken && user.getAccessToken();

  console.warn("accessToken 1: ", accessToken)
  console.warn("requestOptions 1: ", requestOptions, user)

  // Auth for a specific server
  if (requestOptions && requestOptions.auth) {
    if (typeof requestOptions.auth === 'function') {
      // Custom Auth Header
      console.warn("accessToken 2: ", accessToken)
      console.warn("requestOptions 2: ", requestOptions)


      headers.Authorization = requestOptions.auth(requestOptions);
    } else {
      // HTTP Basic Auth (user:password)
      headers.Authorization = `Basic ${btoa(requestOptions.auth)}`;

      console.warn("accessToken 3: ", accessToken)
      console.warn("requestOptions 3: ", requestOptions)

    }
  }
  // Auth for the user's default
  else if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  } else {

    headers.Authorization = 'Bearer '.concat(getCookie('std_loader'));
  }

  return headers;
}
